// services/agb.service.ts
import { Injectable, inject, signal } from '@angular/core';
import {
  Firestore, collection, query, orderBy, getDocs,
  addDoc, updateDoc, doc, Timestamp,
  where,
  limit
} from '@angular/fire/firestore';
import { AGB } from '../models/agb.model';

@Injectable({
  providedIn: 'root'
})
export class AGBService {
  private firestore = inject(Firestore);
  private agbCollection = collection(this.firestore, 'agb');

  private currentAGBSignal = signal<AGB | undefined>(undefined);
  currentAGB = this.currentAGBSignal.asReadonly();

  async loadCurrentAGB(): Promise<AGB | undefined> {
    try {
      const q = query(
        this.agbCollection,
        where('isActive', '==', true),
        orderBy('validFrom', 'desc'),
        limit(1)
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        console.log('No active AGB found');
        this.currentAGBSignal.set(undefined);
        return undefined;
      }

      const agb = {
        id: snapshot.docs[0].id,
        ...snapshot.docs[0].data()
      } as AGB;

      this.currentAGBSignal.set(agb);
      return agb;
    } catch (error) {
      console.error('Error loading AGB:', error);
      throw this.handleError(error, 'Error loading AGB');
    }
  }

  async createAGB(agb: Omit<AGB, 'id'>): Promise<string> {
    try {
      // Convert JS Date to Firestore Timestamp
      const agbData = {
        ...agb,
        createdOn: Timestamp.now(),
      };

      const docRef = await addDoc(this.agbCollection, agbData);
      return docRef.id;
    } catch (error) {
      throw this.handleError(error, 'Error creating AGB');
    }
  }

  async updateAGB(id: string, agb: Partial<AGB>): Promise<void> {
    try {
      const docRef = doc(this.agbCollection, id);
      // Convert Date to Timestamp if present
      const updateData = {
        ...agb,
        updatedOn: Timestamp.now()
      };
      await updateDoc(docRef, updateData);
    } catch (error) {
      throw this.handleError(error, 'Error updating AGB');
    }
  }

  async getAllAGBs(): Promise<AGB[]> {
    try {
      const q = query(this.agbCollection, orderBy('validFrom', 'desc'));
      const snapshot = await getDocs(q);

      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as AGB[];
    } catch (error) {
      throw this.handleError(error, 'Error loading AGBs');
    }
  }

  private handleError(error: unknown, defaultMessage: string): Error {
    if (error instanceof Error) {
      error.message = `${defaultMessage}: ${error.message}`;
      return error;
    }
    return new Error(defaultMessage);
  }
}