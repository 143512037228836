import { Injectable, inject, signal, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { UpdateCheckDialog } from './updateCheckDialog';

@Injectable({
  providedIn: 'root'
})
export class UpdateCheckService {
  private swUpdate = inject(SwUpdate);
  private dialog = inject(MatDialog);

  private updateAvailableSignal = signal(false);
  public updateAvailable = this.updateAvailableSignal.asReadonly();

  isUpdateSupported = computed(() => this.swUpdate.isEnabled);

  canActivateUpdate = computed(() =>
    this.isUpdateSupported() && this.updateAvailable()
  );

  constructor() {
    if (this.isUpdateSupported()) {
      this.setupUpdateCheck();
      this.checkForUpdates();
    }
  }

  private setupUpdateCheck() {
    this.swUpdate.versionUpdates.pipe(
      takeUntilDestroyed()
    ).subscribe(event => {
      if (event.type === 'VERSION_READY') {
        console.log('New version ready:', (event as VersionReadyEvent).latestVersion);
        this.updateAvailableSignal.set(true);
      }
    });

    // Unrecoverable state
    this.swUpdate.unrecoverable.pipe(
      takeUntilDestroyed()
    ).subscribe(event => {
      console.error('SW is in an unrecoverable state:', event.reason);
      window.location.reload();
    });
  }

  async checkForUpdates() {
    if (this.isUpdateSupported()) {
      try {
        const updateFound = await this.swUpdate.checkForUpdate();
        if (updateFound) {
          this.updateAvailableSignal.set(true);
          console.info('A new version is available');
        }
      } catch (err: unknown) {
        console.error('Error checking for updates:', err);
        // err.header = 'Error checking for updates';
        // throw err;
      }
    }
  }

  async activateUpdate() {
    if (this.canActivateUpdate()) {
      try {
        await this.swUpdate.activateUpdate();
        window.location.reload();
      } catch (err) {
        console.error('Error activating update:', err);
      }
    }
  }

  openVersionDialog(version: string, buildDate: string) {
    return this.dialog.open(UpdateCheckDialog, {
      data: { version, buildDate }
    });
  }

}