import { Timestamp } from '@angular/fire/firestore';

export interface SchoolYear {
  startDate: Timestamp;
  isCustomDate: boolean;
  value?: string; // Required for regular years
  customDate?: Date; // Required for custom dates
  confirmedNoRights?: boolean; // Required for custom dates
}

export function getUpcomingSchoolYears(): SchoolYear[] {
  const now = new Date();
  const currentYear = now.getFullYear();
  const years: SchoolYear[] = [];

  for (let year = 2025; year <= currentYear + 3; year++) {
    const summerStart = new Date(year, 7, 1);
    const winterStart = new Date(year + 1, 1, 1);

    if (summerStart > now) {
      years.push({
        value: `${year}_sommer`,
        startDate: Timestamp.fromDate(summerStart),
        isCustomDate: false,
      });
    }

    if (winterStart > now) {
      years.push({
        value: `${year + 1}_winter`,
        startDate: Timestamp.fromDate(winterStart),
        isCustomDate: false,
      });
    }
  }

  return years;
}

export function createCustomSchoolYear(
  date: Date,
  confirmedNoRights: boolean
): SchoolYear {
  return {
    startDate: Timestamp.fromDate(date),
    isCustomDate: true,
    customDate: date,
    confirmedNoRights,
  };
}
