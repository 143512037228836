import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStatus } from '../models/registration.model';

interface StatusConfig {
  type: 'success' | 'warning' | 'error' | 'info';
  styles: {
    background: string;
    color: string;
  };
}

@Pipe({
  name: 'registrationStatus',
  standalone: true,
})
export class RegistrationStatusPipe implements PipeTransform {
  private statusMap: Record<RegistrationStatus, StatusConfig> = {
    new: {
      type: 'info',
      styles: { background: 'var(--theme-accent)', color: '#fff' },
    },
    applicationReview: {
      type: 'info',
      styles: { background: 'var(--theme-accent)', color: '#fff' },
    },
    applicationRejected: {
      type: 'error',
      styles: { background: 'var(--theme-warn)', color: '#fff' },
    },
    waitlist: {
      type: 'warning',
      styles: { background: '#F97316', color: '#fff' },
    },
    contractFinalization: {
      type: 'info',
      styles: { background: 'var(--theme-accent)', color: '#fff' },
    },
    contractReview: {
      type: 'info',
      styles: { background: '#E3F2FD', color: '#fff' },
    },
    contractNotPossible: {
      type: 'error',
      styles: { background: '#FFEBEE', color: '#fff' },
    },
    completed: {
      type: 'success',
      styles: { background: 'var(--theme-success)', color: '#fff' },
    },
    rejected: {
      type: 'error',
      styles: { background: 'var(--theme-warn)', color: 'fff' },
    },
    waitlistNextYear: {
      type: 'warning',
      styles: { background: '#F97316', color: '#fff' },
    },
  };

  transform(status: RegistrationStatus | undefined): {
    status: string;
    type: StatusConfig['type'];
    styles: StatusConfig['styles'];
  } | null {
    if (!status) return null;
    const config = this.statusMap[status] || {
      type: 'info',
      styles: { background: '#E3F2FD', color: '#1565C0' },
    };
    return {
      status,
      type: config.type,
      styles: config.styles,
    };
  }
}
