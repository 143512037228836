/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { UpdateCheckService } from "./updateCheck.service";

@Component({
    selector: "update-check-dialog",
    template: `
        <mat-dialog-content>
        <div><strong>Version</strong> {{data.version}}</div>
        <div><strong>Datum</strong> {{data.buildDate}}</div>
        @if(!updateCheckService.updateAvailable()) {
            <button mat-raised-button color="accent" (click)="checkForUpdates()" [disabled]="versionChecking">
            @if(versionChecking) {
                <div class="progress">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            } @else {
                <div class="iconText">
                <mat-icon>cached</mat-icon>
                <span>Auf Updates prüfen </span>
                </div>
            }
            </button>
        }
        @else {
            <button mat-raised-button color="primary" (click)="refresh()">
            <div class="iconText">
                <mat-icon>arrow_circle_up</mat-icon>
                <span>Updates verfügbar </span>
            </div>
            </button>
        }
        </mat-dialog-content>
  `,
    styles: [`
    mat-dialog-content{
        display: grid;
        align-items: center;
        min-width: 20rem;
        gap: 1rem;
        button {
        .progress {
          min-width: 10rem;
        }
        .iconText {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
        `],
    imports: [MatDialogModule, MatButtonModule, MatProgressBarModule, MatIconModule]
})
export class UpdateCheckDialog {
    dialogRef = inject(MatDialogRef<UpdateCheckDialog>);
    updateCheckService = inject(UpdateCheckService);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    versionChecking = false;

    ngOnInit() {
        this.checkForUpdates();
    }

    async checkForUpdates() {
        this.versionChecking = true;
        try {
            await this.updateCheckService.checkForUpdates();
        }
        finally {
            this.versionChecking = false;
        }
    }

    async refresh() {
        window.location.reload();
    }


}
