import { isPlatformBrowser } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";

export interface DeviceInfo {
    os: string;
    browser: string;
    screenResolution: string;
    language: string;
    version: string;
}

@Injectable({
    providedIn: 'root'
})
export class DeviceInfoService {
    platformId = inject(PLATFORM_ID);

    getDeviceInfo(versionCustomer: string, versionEmployee: string): DeviceInfo | null {
        if (!isPlatformBrowser(this.platformId)) {
            return null; // Return null if not in a browser environment
        }

        const ua = navigator.userAgent;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const language = navigator.language;

        const browserInfo = this.detectBrowser(ua);
        const osInfo = this.detectOS(ua);
        const appName = this.getAppName(versionCustomer, versionEmployee);

        let version: string;
        switch (appName) {
            case 'customer':
                version = versionCustomer;
                break;
            case 'employee':
                version = versionEmployee;
                break;
            default:
                version = 'unknown';
                break;
        }

        return {
            os: osInfo,
            browser: `${browserInfo.name} ${browserInfo.version}`,
            screenResolution: `${screenWidth}x${screenHeight}`,
            language,
            version
        };
    }

    private detectBrowser(ua: string): { name: string; version: string; } {
        const browsers = [
            { name: 'Chrome', regex: /Chrome\/(\S+)/ },
            { name: 'Firefox', regex: /Firefox\/(\S+)/ },
            { name: 'Safari', regex: /Version\/(\S+).*Safari/ },
            { name: 'Edge', regex: /Edg\/(\S+)/ },
            { name: 'Opera', regex: /OPR\/(\S+)/ },
            { name: 'IE', regex: /Trident.*rv:(\S+)/ }
        ];

        for (const browser of browsers) {
            const match = ua.match(browser.regex);
            if (match) {
                return { name: browser.name, version: match[1] };
            }
        }

        return { name: 'Unknown', version: 'Unknown' };
    }

    private detectOS(ua: string): string {
        if (ua.includes('Win')) return 'Windows';
        if (ua.includes('Mac')) return 'macOS';
        if (ua.includes('Linux')) return 'Linux';
        if (ua.includes('Android')) return 'Android';
        if (ua.includes('iOS')) return 'iOS';
        return 'Unknown OS';
    }

    private getAppName(versionCustomer: string, versionEmployee: string): string {
        try {
            // Get the current script path
            const currentScript = document?.currentScript as HTMLScriptElement;
            const scriptPath = currentScript?.src || '';

            // Check if it contains specific app identifiers
            if (scriptPath.includes('/customer/')) {
                return 'customer';
            } else if (scriptPath.includes('/employee/')) {
                return 'employee';
            }

            // Fallback: Check the window location
            const pathname = window?.location?.pathname || '';
            if (pathname.startsWith('/customer')) {
                return 'customer';
            } else if (pathname.startsWith('/employee')) {
                return 'employee';
            }

            // Another fallback: Check which version is defined
            if (versionCustomer) {
                return 'customer';
            } else if (versionEmployee) {
                return 'employee';
            }

            return 'unknown';
        } catch {
            return 'unknown';
        }
    }
}