import { Pipe, PipeTransform } from '@angular/core';
import { SchoolYear } from '../models/schoolyear.model';

@Pipe({
  name: 'schoolYear',
  standalone: true,
})
export class SchoolYearPipe implements PipeTransform {
  transform(
    value: SchoolYear | string | undefined
  ): { year: string; semester: 'summer' | 'winter' } | null {
    if (!value) return null;

    let yearValue: string;
    if (typeof value === 'string') {
      yearValue = value;
    } else if ('value' in value) {
      yearValue = value.value ?? '';
    } else {
      return null;
    }

    const parts = yearValue.split('_');
    if (parts.length !== 2) return null;

    return {
      year: parts[0],
      semester: parts[1] as 'summer' | 'winter',
    };
  }
}
